body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}

.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right;
}

.ant-radio-button-wrapper-checked {
  background-color: black !important;
  border-color: black !important;
}

.ant-radio-button-checked {
  background-color: black;
  border-color: black;
}

.table-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.table-header-wrapper {
  padding: 16px;
  border-bottom: 1px solid #9d9d9d;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-header-wrapper .table-header {
  margin-bottom: 0;
  width: 50%;
}
.table-header-wrapper h1 {
  font-size: 32px;
  font-weight: bold;
  line-height: 1.69;
  letter-spacing: -0.6px;
  color: black;
  margin: 0;
}

.tabs {
  background: white;
  overflow: hidden;
  display: grid;
  gap: 16px;
  grid-auto-flow: column;
  width: fit-content;
  margin-top: 8px;
  padding-bottom: 16px;
}

.tabs a {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.67;
  color: #2f2f2f;
  position: relative;
  border-radius: 8px;
  display: block;
}
.tabs a.active {
  color: #007fed;
}

.tabs a:not(.active):hover {
  background: #efefef;
}

.tabs a.active::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 4px;
  bottom: -6px;
  border-radius: 4px;
  background: #007fed;
}

.table-striped-rows tr:nth-child(even) {
  background-color: #fafafa;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 10;
  padding-left: 24px;
}

.sider {
  padding-top: 60px;
  height: 100vh;
  position: fixed;
  overflow-y: auto;
  left: 0;
}

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagePreview {
  display: flex;
  position: relative;
  width: max-content;
}
.imagePreview img {
  height: 200px;
}
.imagePreview .dummy {
  height: 200px;
  width: 200px;
  background: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  color: white;
}
.imagePreview .actionBar {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: 0.3s;
}

.imagePreview .actionBar:hover {
  background-color: #0006;
}

.imagePreview .actionBar:hover span {
  display: flex;
}
.imagePreview .actionBar span {
  display: none;
  cursor: pointer;
  width: 25px;
  height: 25px;
  background-color: #2f2f2f;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
}

.gallery-image-wrapper {
  height: calc(70vh - 100px);
  margin-top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 10px;
}
.gallery-image-wrapper .photo-item {
  border: solid transparent 4px;
  padding: 2px;
}

.gallery-image-wrapper .photo-item.active {
  border-color: #1890ff;
}

.gallery-image-wrapper .photo-item.active .checkMark {
  display: block !important;
}

.gallery-image-wrapper .photo-item img {
  width: 100%;
  cursor: pointer;
}
.gallery-image-wrapper .photo-item .checkMark {
  display: none;
  position: absolute;
  top: -5px;
  right: -5px;
}
.gallery-image-wrapper .photo-item .checkMark .checked {
  display: block;
}
.gallery-image-wrapper .photo-item .checkMark .remove {
  display: none;
}

.gallery-image-wrapper .photo-item .checkMark:hover .checked {
  display: none;
}

.gallery-image-wrapper .photo-item .checkMark:hover .remove {
  display: block;
}
.gallery-image-wrapper .photo-item .checkMark span {
  color: #1890ff;
  font-size: 24px;
  background: #fff;
  border: solid 1px;
}

.content {
  border-bottom: solid #ccc 1px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.cityRow {
  display: flex;
  align-items: flex-start;
}

.ant-tabs-ink-bar {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  height: 4px !important;
}

.g-rating-bar {
  background-size: 13px 12px;
  height: 13px;
  top: 1px;
  width: 65px;
  background-repeat: repeat-x;
  display: inline-block;
  overflow: hidden;
  position: relative;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAmCAQAAAAYCMGrAAAA+klEQVR4AcWV4cbtMBBFF0MIVUopoVSrhDDv/3gf/RFRpzdNOty1HiBO99mzeYWgCMZMKCPGrCgrxiSUhCkDeukxJKCXAUMiehkxw6FZhxEzmp0x4kCzByYISqlYdal0supS6WrVpdLEK0YSamJiJOPY0c/uOG4s6CcXfuKJaJcRzyNCQJsNiF1sRTR1hP11NNJ8RCrONOPRf+r7J+TZgQ5CNfMOYvW/2YxDqzqA/57+gVY9eiakrnyZEGXDsaE3p/4JScwPX3rtnZATDxnPWT7X16XAHaH8HWNrlxJD9TyGti5tCM84zpZe+RxNjeX9tZqLaGoMxN/P/wHP5Vw+8ZxnEQAAAABJRU5ErkJggg==);
}

.g-rating-star {
  background-size: 13px 12px;
  height: 13px;
  top: 1px;
  width: 65px;
  background-repeat: repeat-x;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAmBAMAAABALxQTAAAAFVBMVEVMaXH4twP4twP4twP4twP4twP4twP7w8S/AAAAB3RSTlMAFv5uPpvQloUsTQAAAMFJREFUeAGE0TEOgzAMQFEXoDNiYC6/wFxxAsTADDkB5f6HqNRENXUi8TYiRfnY8lNXkjBOkuBWSeAhsYJOYiW9xO4MEqshkTbCSyIH7GLdgFasHHgmwkikZQD6OROZRG4Hxju8o/TNhbNhCqkOxaZDVKdxNnq/EjUS/A2o0PuXpyVeb9bjDWY9QSWXDQfBbtbjtWY9bM4sqfx+5yYt8wNcAFEzrGGkk5668KsFrKewPtQ3aFqh8WOnYZ+lIBQkgykAWk8rlAqcHfQAAAAASUVORK5CYII=);
}

.photos-checkbox-group {
  position: absolute;
  z-index: 10;
  top: 8px;
  right: 8px;
}
.photos-checkbox-group .checkbox {
  color: #1890ff;
  font-size: 24px;
  height: 26px;
  width: 26px;
  background: #fff;
  display: flex;
  border-radius: 50%;
  border: solid 1px #d9d9d9;
  cursor: pointer;
  /* display:block;
    width:16px;
    height: 16px;
    border-radius:50%;
    border:solid 1px #d9d9d9; */
}
